.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  height: 50px;
  width: 50px;
  color: #ffab00;
  cursor: pointer;
}
.icon-style:hover {
  color: #000;
  //   border: 2px solid #551b54;
  //   animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
// @keyframes movebtn {
//   0% {
//     transform: translateY(0px);
//   }
//   25% {
//     transform: translateY(20px);
//   }
//   50% {
//     transform: translateY(0px);
//   }
//   75% {
//     transform: translateY(-20px);
//   }
//   100% {
//     transform: translateY(0px);
//   }
// }
