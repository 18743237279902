/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #000;
$primary: #fff;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4d4d4d;
$gray: #fff;
$accent: #ffab00;
$brown: #4d2612;
